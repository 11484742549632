html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:'';content:none}table{border-collapse:collapse;border-spacing:0}

body {
    font-family: 'Ubuntu', sans-serif;
    color: #333;
}

.content {
    padding-top: 70px;
    height: calc(100% - 70px);
}

.centered-text {
    margin-top: 40px;
    text-align: center;
}

.centered-text h4 {
    font-size: 28px;
    margin-top: 60px;
    margin-bottom: 30px;
    padding-left: 5%;
    padding-right: 5%;
}

.centered-text p {
    font-size: 20px;
    margin-bottom: 15px;
    padding-left: 10%;
    padding-right: 10%;
}

.centered-text a {
    color: #00C6FF;
}

.bold {
    font-weight: bold;
}

.uppercase {
    text-transform: uppercase;
}

.full-width {
    width: 100%;
}

.button-progress {
    height: 30px !important;
    width: 30px !important;
}

.action-progress {
    height: 24px !important;
    width: 24px !important;
    padding-left: 5px;
    padding-right: 5px;
}

.button-icon {
    cursor: pointer;
    padding-left: 5px;
    padding-right: 5px;
}

.MuiDialogTitle-root h2 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 28px;
    text-align: center;
}


.tutor-container {
    width: 300px;
    margin: 30px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Ubuntu', sans-serif;
    font-size: 16px;
}

.tutor-container img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
}

.tutor-container h5 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 15px;
    font-weight: bold;
}

.tutor-container h6 {
    font-size: 14px;
    text-transform: uppercase;
    margin-top: 5px;
    margin-bottom: 5px;
}

.dropzone {
    text-align: center;
    padding: 20px;
    border: 3px dashed gray;
    background-color: #fafafa;
    color: gray;
}

.dropzone--error {
    border-color: red;
    color: red;
}

.image-preview-container {
    display: flex;
    align-items: center;
}

.image-preview {
    width: 200px;
}

.progress-container {
    width: 100%;
    text-align: center;
    margin-top: 40px;
}

.register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.register-box {
    border-radius: 2px;
    box-shadow: 0px 19px 3px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 19px 3px rgba(0,0,0,0.75);
    position: relative;
    margin-bottom: 10%;
}

.register-container form {
    display: flex;
    flex-direction: column;
    padding: 40px 100px;
    width: 300px;
}

.register-container form button {
    margin-top: 10px;
}

.register-container form h3 {
    text-transform: uppercase;
    font-size: 22px;
    margin-bottom: 16px;
}

.register-container form .MuiFormControl-root {
    margin-top: 4px;
}

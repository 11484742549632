.homepage-image-container {
    position: relative;
}

.homepage-image {
    width: 100%;
    height: 500px;
    object-fit: cover;
}

.homepage-image-shaddow {
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    color: white;
}

.MuiButton-containedPrimary .MuiButton-label {
    color: white;
    padding-left: 40px;
    padding-right: 40px;
    font-size: 16px;
    padding-top: 6px;
    padding-bottom: 4px;
}

.header-notifcation {
    position: absolute;
    left: 42px;
    top: 50px;
    font-size: 20px;
}

.header-social {
    position: absolute;
    right: 42px;
    top: 50px;
    display: flex;
    justify-content: flex-end;
}

.header-social svg {
    border: 1px solid white;
    border-radius: 100%;
    padding: 6px;
    cursor: pointer;
    transition: background-color .5s;
}

.header-social svg:hover {
    background-color: #00C6FF;
    border-color: #00C6FF;
}

.header-social-inner {
    display: flex;
    overflow: hidden;
    transition: all 0.5s;
}

.header-social-inner svg {
    margin-right: 10px;
}

.homepage-image-shaddow h1 {
    text-transform: uppercase;
    font-size: 50px;
    font-weight: bold;
    margin-bottom: 50px;
}

.homepage-image-shaddow h3 {
    font-size: 22px;
    max-width: 750px;
    text-align: center;
    margin-bottom: 15px;
    margin-left: 5%;
    margin-right: 5%;
}

.homepage-image-shaddow button {
    margin-top: 80px;
    margin-bottom: 50px;
}

.animation-section-container {
    position: relative;
    margin-right: 10%;
    min-height: 600px;
}

.animation-section-text {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 45%;
    text-align: left;
}

.animation-section-text h4 {
    font-size: 28px;
    margin-bottom: 40px;
}

.animation-section-text p {
    font-size: 20px;
    margin-bottom: 40px;
}

.upper-level {
    border-bottom: 3px solid rgba(0,0,0,0.1);
    width: 100%;
    padding-bottom: 50px;
}

.animation-section-text .MuiButton-label {
    color: white;
    font-size: 16px;
    padding-top: 6px;
    padding-bottom: 4px;
}

.homepage-container {
    margin-bottom: 100px;
}

.tutors-container {
    margin-top: 60px;
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

@media screen and (max-width: 1000px) {
    #mainPartition {
        display: none;
    }

    .animation-section-text {
        width: 100%;
        margin-left: 10%;
    }

    .animation-section-container {
        margin-left: 10%;
    }

    .navbar-right-aligned {
        display: none;
    }

    .navbar-left-aligned {
        display: block;
    }

    .header-social {
        display: none;
    }

    .navbar-name a {
        font-size: 28px;
    }

    .homepage-image-shaddow h1 {
        font-size: 36px;
    }

    .MuiButton-containedPrimary .MuiButton-label {
        padding-left: 20px;
        padding-right: 20px;
    }
}

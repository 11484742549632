.navbar {
    width: 100%;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background-color: white;
    height: 70px;
    z-index: 1;
}

.navbar-right-aligned {
    position: absolute;
    right: 0;
}


.navbar-left-aligned {
    display: none;
    position: absolute;
    left: 0;
}

.navbar-left-aligned svg {
    height: 30px;
    width: 30px;
    margin-left: 6px
}

.navbar-right-aligned a, .navbar-right-aligned span {
    font-size: 14px;
    text-decoration: none;
    color: #333;
    text-transform: uppercase;
    margin-right: 20px;
    position: relative;
    bottom: -1px;
    cursor: pointer;
}

.navbar-name a {
    font-size: 40px;
    color: #333;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    position: relative;
    bottom: -2px;
}

.drawer {
    position: relative;
}

.drawer h4 {
    font-size: 26px;
    padding-bottom: 40px;    
    width: 300px;
    margin-top: 60px;
    padding-left: 40px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
}

.drawer h6, .drawer a {
    font-size: 16px;
    padding-left: 40px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    padding-top: 20px;
    padding-bottom: 20px;
    cursor: pointer;
    text-decoration: none;
    color: black;
    padding-right: 20px
}

.submenu {
    margin-left: 20px;
}

.close-drawer {
    position: absolute !important;
    right: 20px;
    top: 34px;
}

.close-drawer svg {
    height: 36px;
    width: 36px;
}

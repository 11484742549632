.profile__text {
    margin-right: 20%;
    font-size: 26px;
    margin-top: 100px;
    text-align: right;
}

.profile__value {
    font-weight: bold;
    text-align: left;
    font-size: 30px;
    min-width: 40%;
    display: inline-block;
    margin-top: 20px;
    margin-left: 20px;
}
#mainPartition{position: relative; left: -20px; top: 60px; width: calc(100% + 40px); height: 600px; background:#fff;}
#mainPartition #mainPartitionBck{position: absolute; left: 0px;  top: 0px; width: 100%; height: 100%; background:rgba(0,0,0,0.05);}

#mainPartition #mainPartitionLeft{position: absolute; width:55%; height: 100%;}
#mainPartition #mainPartitionLeft #mainPartitionLeftCirclesPart{position: absolute; width:500px; height: 500px; top:50px; left:calc(50% - 250px);}

#mainPartition #mainPartitionLeft #mainPartitionLeftCirclesPart #middleCircle{position: absolute; width: 100px; height: 100px; left: calc(50% - 50px); top:calc(50% - 50px); border:1px solid rgba(0,0,0,0.1); border-radius: 50%;}
#mainPartition #mainPartitionLeft #mainPartitionLeftCirclesPart #middleCircle:hover{cursor: pointer;}
#mainPartition #mainPartitionLeft #mainPartitionLeftCirclesPart #middleCircle h1{font-size:40px; margin:0px; text-align:center; margin-top:32px;}

@keyframes rotate {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}

@keyframes rotate2 {
    from {transform: rotate(360deg);}
    to {transform: rotate(0deg);}
}

#mainPartition #mainPartitionLeft #mainPartitionLeftCirclesPart #secondCircle{position: absolute; width: 300px; height: 300px; border-radius: 50%; border:1px solid rgba(0,0,0,0.1); left:calc(50% - 150px); top:calc(50% - 150px);  animation-name: rotate; animation-fill-mode:forwards; animation-duration: 40s;  animation-iteration-count: infinite; animation-timing-function: linear; }

#mainPartition #mainPartitionLeft #mainPartitionLeftCirclesPart #secondCircle .secondCirclePart{position: absolute; left: 213.2px; top: 246.4px; width: 50px; height: 50px; border-radius: 50%; background:#00C6FF;  animation-name: rotate2; animation-fill-mode:forwards; animation-duration: 40s;  animation-iteration-count: infinite; animation-timing-function: linear; }
#mainPartition #mainPartitionLeft #mainPartitionLeftCirclesPart #secondCircle .secondCirclePart:hover{background:#f96816; cursor:pointer;}
#mainPartition #mainPartitionLeft #mainPartitionLeftCirclesPart:hover #secondCircle .secondCirclePart{-webkit-animation-play-state: paused;}
#mainPartition #mainPartitionLeft #mainPartitionLeftCirclesPart #secondCircle .secondCirclePart img{position: absolute; left: 13px; top: 12px; width:26px; height:26px;}
#mainPartition #mainPartitionLeft #mainPartitionLeftCirclesPart #secondCircle .secondCirclePart1{left:220px; top:10px;}
#mainPartition #mainPartitionLeft #mainPartitionLeftCirclesPart #secondCircle .secondCirclePart2{left:93.8px; top: -21.7px;}
#mainPartition #mainPartitionLeft #mainPartitionLeftCirclesPart #secondCircle .secondCirclePart3{left:-8.65px; top:56.9px;}
#mainPartition #mainPartitionLeft #mainPartitionLeftCirclesPart #secondCircle .secondCirclePart4{left: -12px; top: 186px;}
#mainPartition #mainPartitionLeft #mainPartitionLeftCirclesPart #secondCircle .secondCirclePart5{left: 86.2px; top: 270px;}
#mainPartition #mainPartitionLeft #mainPartitionLeftCirclesPart #secondCircle .secondCirclePart6{left: 274.8px; top: 132.85px;}


#mainPartition #mainPartitionLeft #mainPartitionLeftCirclesPart #thirdCircle{ animation-name: rotate2; animation-fill-mode:forwards; animation-duration: 80s;  animation-iteration-count: infinite; animation-timing-function: linear; position: absolute; left: 0px; top: 0px; width: 500px; height: 500px; border-radius: 50%; border:1px solid rgba(0,0,0,0.1);}
#mainPartition #mainPartitionLeft #mainPartitionLeftCirclesPart #thirdCircle .thirdCirclePart { position: absolute; left: 480px; top: 230px; width: 40px; height: 40px; border-radius: 50%; background:#00C6FF;  animation-name: rotate; animation-fill-mode:forwards; animation-duration: 80s;  animation-iteration-count: infinite; animation-timing-function: linear; }
#mainPartition #mainPartitionLeft #mainPartitionLeftCirclesPart #thirdCircle .thirdCirclePart:hover{background:#f96816; cursor:pointer;}
#mainPartition #mainPartitionLeft #mainPartitionLeftCirclesPart:hover #thirdCircle .thirdCirclePart{-webkit-animation-play-state: paused;}
#mainPartition #mainPartitionLeft #mainPartitionLeftCirclesPart #thirdCircle .thirdCirclePart img{position: absolute; left: 10px; top: 10px; width:20px; height:20px;}

#mainPartition #mainPartitionLeft #mainPartitionLeftCirclesPart #thirdCircle .thirdCirclePart2 { left: 454.7px; top: 120.41px; }
#mainPartition #mainPartitionLeft #mainPartitionLeftCirclesPart #thirdCircle .thirdCirclePart3 { left: 378.92px; top: 28px; }
#mainPartition #mainPartitionLeft #mainPartitionLeftCirclesPart #thirdCircle .thirdCirclePart4 { left: 276.98px; top: -14.54px; }
#mainPartition #mainPartitionLeft #mainPartitionLeftCirclesPart #thirdCircle .thirdCirclePart5 { left: 159.52px; top: -12.57px; }
#mainPartition #mainPartitionLeft #mainPartitionLeftCirclesPart #thirdCircle .thirdCirclePart6 { left: 69.31px; top: 38.489px; }
#mainPartition #mainPartitionLeft #mainPartitionLeftCirclesPart #thirdCircle .thirdCirclePart7 { left: 2.39px; top: 128.32px; }
#mainPartition #mainPartitionLeft #mainPartitionLeftCirclesPart #thirdCircle .thirdCirclePart8 { left: -19.85px; top: 238.725px; }
#mainPartition #mainPartitionLeft #mainPartitionLeftCirclesPart #thirdCircle .thirdCirclePart9 { left: 9.26px; top: 347.37px; }
#mainPartition #mainPartitionLeft #mainPartitionLeftCirclesPart #thirdCircle .thirdCirclePart10 { left: 83.05px; top: 433.25px; }
#mainPartition #mainPartitionLeft #mainPartitionLeftCirclesPart #thirdCircle .thirdCirclePart11 { left: 181.59px; top: 476.2px; }
#mainPartition #mainPartitionLeft #mainPartitionLeftCirclesPart #thirdCircle .thirdCirclePart12 { left: 298.91px; top: 470.32px; }
#mainPartition #mainPartitionLeft #mainPartitionLeftCirclesPart #thirdCircle .thirdCirclePart13 { left: 397.28px; top: 415.79px; }
#mainPartition #mainPartitionLeft #mainPartitionLeftCirclesPart #thirdCircle .thirdCirclePart14 { left: 461.79px; top: 323.65px; }

#mainPartition #mainPartitionLeft #mainPartitionLeftCirclesPart:hover #secondCircle,
#mainPartition #mainPartitionLeft #mainPartitionLeftCirclesPart:hover #thirdCircle{-webkit-animation-play-state: paused;}

#mainPartition #mainPartitionRight{position: absolute; left: 55%; width:calc(45% - 42px); height: 100%; overflow:hidden;}
#mainPartition #mainPartitionRight #mainPartitionRightHeader{position: absolute; text-align:center; left: 0px; top: 50px; width: 100%; height: 100px; border-bottom:3px solid rgba(0,0,0,0.1);}
#mainPartition #mainPartitionRight #mainPartitionRightHeader .mainPartitionRightHeaderPart{position: absolute; left: 0px; top: 0px; width: 33%; height: 100%;}
/* #mainPartition #mainPartitionRight #mainPartitionRightHeader .mainPartitionRightHeaderPart:hover{border-bottom:3px solid #f96816; } */
#mainPartition #mainPartitionRight #mainPartitionRightHeader .mainPartitionRightHeaderPart0{border-bottom:3px solid #00C6FF; }
#mainPartition #mainPartitionRight #mainPartitionRightHeader .mainPartitionRightHeaderPart1{left:33%;}
#mainPartition #mainPartitionRight #mainPartitionRightHeader .mainPartitionRightHeaderPart2{left:66%;}

#mainPartition #mainPartitionRight #mainPartitionRightHeader .mainPartitionRightHeaderPart h3{  position: absolute; left: 0px; bottom: 30px; margin:0px; font-size:24px; margin-left:0px; font-weight: lighter; width:100%; text-align:center;}
#mainPartition #mainPartitionRight #mainPartitionRightHeader .mainPartitionRightHeaderPart h3:hover{cursor: pointer;}

#mainPartition #mainPartitionRight .mainPartitionRightBody{position: absolute; text-align:center; transition: all 0.2s ease-in-out; left: 0px; top: 160px; width: calc(100% - 42px); height: calc(100% - 160px);}
#mainPartition #mainPartitionRight .mainPartitionRightBody2{left: 100%;}
#mainPartition #mainPartitionRight .mainPartitionRightBody .mainPartitionRightBodyHeader{position: absolute; left: 0px; top: 0px; width: 100%; height: 60px;}
#mainPartition #mainPartitionRight .mainPartitionRightBody .mainPartitionRightBodyHeader h4{ font-size:26px; font-weight: lighter;}
#mainPartition #mainPartitionRight .mainPartitionRightBody .mainPartitionRightBodyBody{position: absolute; left: 0px; top: 80px; width: 100%; height: calc(100% - 100px);}
#mainPartition #mainPartitionRight .mainPartitionRightBody .mainPartitionRightBodyBody p{ font-size:18px;}

#mainPartition #mainPartitionRight .mainPartitionRightBody .mainPartitionRightBodyBody .mainPartitionRightBodyBodyButtons{position: relative; left: 0px; top: 30px; width: 100%;  height: 0px;}
#mainPartition #mainPartitionRight .mainPartitionRightBody .mainPartitionRightBodyBody .mainPartitionRightBodyBodyButtons .mainPartitionRightBodyBodyButtons{position: relative; display: inline-block; background:#00C6FF; width:auto; left: 0px; top: 0px; height: 40px; border-radius: 3px;}
#mainPartition #mainPartitionRight .mainPartitionRightBody .mainPartitionRightBodyBody .mainPartitionRightBodyBodyButtons .mainPartitionRightBodyBodyButtons:hover{cursor: pointer; background:#f96816;}
#mainPartition #mainPartitionRight .mainPartitionRightBody .mainPartitionRightBodyBody .mainPartitionRightBodyBodyButtons .mainPartitionRightBodyBodyButtons a{margin:0px; display: block; color:#fff; margin:0px 30px; font-size:18px;  margin-top:8px;}
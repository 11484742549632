.courses {
    margin-left: 10%;
    margin-right: 10%;
}

.courses h1 {
    font-size: 50px;
    text-align: center;
    margin-top: 5%;
    margin-bottom: 5%;
}

.courses-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.course-outter-container {
    padding-top: 70px;
    width: 500px;
}

.course-container {
    border-radius: 10px;
    overflow: hidden;
    background-color: #ebe6ea;
    display: inline-block;
    margin-left: 30px;
    margin-right: 30px;
    padding-bottom: 10px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
}
  
.course-container:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.course-container img {
    width: 100%;
    transition:transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.course-image-container {
    position: relative;
}

.course-image-container h4 {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 34px;
}

.course-details-container {
    font-size: 17px;
    margin: 10px 20px;
    display: flex;
    flex-direction: column;
}

.filter-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.filter-container button {
    margin: 20px;
}

.description-limiter {
    height: 204px;
    overflow: hidden;
}

.professors-span {
    height: 30px;
}

.professor-name {
    color: #00C6FF;
    cursor: pointer;
}

@media screen and (max-width: 1000px) {
    .course-image-container h4 {
        top: 20px;
    }

    .courses {
        margin-left: 0;
        margin-right: 0;
    }
}

.contact-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 71px);
}

.contact-box {
    border-radius: 2px;
    box-shadow: 0px 19px 3px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 19px 3px rgba(0,0,0,0.75);
    position: relative;
}

.contact-box form {
    display: flex;
    flex-direction: column;
    padding: 20px 50px 30px 260px;
    width: 300px;
}

.contact-box form h3 {
    text-transform: uppercase;
    font-size: 22px;
    margin-bottom: 16px;
}

.contact-box form button {
    margin-top: 10px;
}

.contact-box form .MuiFormControl-root {
    margin-top: 4px;
}

.contact-left-box {
    width: 280px;
    height: 340px;
    background: #333;
    position: absolute;
    left: -80px;
    top: 50px;
    border-radius: 2px;
    box-shadow: 0px 19px 3px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 19px 3px rgba(0,0,0,0.75);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contact-left-box h4 {
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 15px;
    text-align: center;
}

.contact-left-box div {
    display: flex;
    align-items: center;
    margin-left: 15px;
    margin-top: 10px;
}

.contact-left-box div svg {
    margin-right: 3px;
}

.contact-social svg {
    padding: 5px;
    border: 1px solid white;
    border-radius: 100%;
    cursor: pointer;
}

.contact-social svg:hover {
    background-color: #00C6FF;
    border-color: #00C6FF;
}

@media screen and (max-width: 1000px) {
    .contact-box {
        box-shadow: none;
        -webkit-box-shadow: none;
    }

    .contact-box form {
        padding: 0;
        padding-top: 240px;
        box-shadow: none;
        -webkit-box-shadow: none;
    }

    .contact-left-box {
        position: relative;
        left: 0px;
        height: 240px;
    }
}

.admin-dashboard-container-wrapper {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    height: 100%;
}

.admin-dashboard-container-wrapper img {
    width: 50px;
    padding: 40px 75px 30px 75px;
}

.admin-dashboard-navigation {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    width: 215px;
    height: 100%;
    position: fixed;
}

.admin-dashboard-navigation li {
    color: gray;
    text-transform: uppercase;
    padding: 20px 60px;
    cursor: pointer;
    border-left: 8px solid transparent;
}

.admin-dashboard-navigation li.active {
    color: #00C6FF;
    border-color: #00C6FF;
}

.admin-dashboard-container {
    margin-left: 30px;
    margin-right: 50px;
    margin-top: 30px;
    width: 100%;
    padding-left: 215px;
}

.admin-dashboard-container .MuiTableContainer-root {
    margin-top: 30px;
}

.admin-dashboard-container .MuiTableContainer-root .MuiTableCell-head {
    font-weight: bold;
}

.admin-dashboard-container .MuiTableContainer-root .MuiTableCell-body {
    color: inherit !important;
}

.admin-dashboard-add-container {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.admin-dashboard-add-container h1 {
    flex: 1;
    font-size: 38px;
}

.analytics-container {
    position: relative;
}

.analytics-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 115px;
    background: white;
}
